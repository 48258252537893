import { defineMessages } from "react-intl";

export const TIMELINE = [
  {
    year: 1959,
    title: defineMessages({
      timelineTitle1: {
        id: "SpradlingTimeline.timelineTitle1",
        description: "Timeline 1 in 'We make history' section",
        defaultMessage: "El inicio",
      },
    }).timelineTitle1,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary1: {
        id: "SpradlingTimeline.timelineSummary1",
        description: "Timeline 1 in 'We make history' section",
        defaultMessage:
          "Nació Productos Químicos Nacionales (hoy Proquinal S. A.). Se construyeron la planta y las oficinas.",
      },
    }).timelineSummary1,
  },
  {
    year: 1981,
    title: defineMessages({
      timelineTitle2: {
        id: "SpradlingTimeline.timelineTitle2",
        description: "Timeline 2 in 'We make history' section",
        defaultMessage: "Líderes en exportación",
      },
    }).timelineTitle2,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2: {
        id: "SpradlingTimeline.timelineSummary2",
        description: "Timeline 2 in 'We make history' section",
        defaultMessage:
          "El Presidente de Colombia nos hace entrega de la Medalla de Plata del Exportador.",
      },
    }).timelineSummary2,
  },
  {
    year: 1986,
    title: defineMessages({
      timelineTitle3: {
        id: "SpradlingTimeline.timelineTitle3",
        description: "Timeline 3 in 'We make history' section",
        defaultMessage: "Alianzas estratégicas",
      },
    }).timelineTitle3,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary3: {
        id: "SpradlingTimeline.timelineSummary3",
        description: "Timeline 3 in 'We make history' section",
        defaultMessage:
          "Consolidamos la alianza comercial con Spradling International Inc., como representante exclusivo en Estados Unidos y con All Cargo Intermodal Forwarding Inc. – ACIF -, como operador logístico en Miami, Estados Unidos.",
      },
    }).timelineSummary3,
  },
  {
    year: 1997,
    title: defineMessages({
      timelineTitle4: {
        id: "SpradlingTimeline.timelineTitle4",
        description: "Timeline 4 in 'We make history' section",
        defaultMessage: "Mercado Alemán",
      },
    }).timelineTitle4,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary4: {
        id: "SpradlingTimeline.timelineSummary4",
        description: "Timeline 4 in 'We make history' section",
        defaultMessage:
          "Consolidamos la alianza comercial con Spradling International GmbH, como representante exclusivo en Alemania.",
      },
    }).timelineSummary4,
  },
  {
    year: 2004,
    title: defineMessages({
      timelineTitle5: {
        id: "SpradlingTimeline.timelineTitle5",
        description: "Timeline 5 in 'We make history' section",
        defaultMessage: "Presentes en España",
      },
    }).timelineTitle5,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary5: {
        id: "SpradlingTimeline.timelineSummary5",
        description: "Timeline 5 in 'We make history' section",
        defaultMessage:
          "Se consolidó la alianza comercial con Euro Spradling, como representante exclusivo en España.",
      },
    }).timelineSummary5,
  },
  {
    year: 2004,
    title: defineMessages({
      timelineTitle6: {
        id: "SpradlingTimeline.timelineTitle6",
        description: "Timeline 6 in 'We make history' section",
        defaultMessage: "Costa Rica se pone en marcha",
      },
    }).timelineTitle6,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary6: {
        id: "SpradlingTimeline.timelineSummary6",
        description: "Timeline 6 in 'We make history' section",
        defaultMessage:
          "Se inauguró la planta con un área de construcción de 15.000 m2, en un terreno de 68.000 m2.",
      },
    }).timelineSummary6,
  },
  {
    year: 2009,
    title: defineMessages({
      timelineTitle7: {
        id: "SpradlingTimeline.timelineTitle7",
        description: "Timeline 7 in 'We make history' section",
        defaultMessage: "Cautivando el mercado del Reino Unido",
      },
    }).timelineTitle7,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary7: {
        id: "SpradlingTimeline.timelineSummary7",
        description: "Timeline 7 in 'We make history' section",
        defaultMessage:
          "Se consolidó la alianza comercial con CTP Textiles, como representante exclusivo en el Reino Unido.",
      },
    }).timelineSummary7,
  },
  {
    year: 2009,
    title: defineMessages({
      timelineTitle8: {
        id: "SpradlingTimeline.timelineTitle8",
        description: "Timeline 8 in 'We make history' section",
        defaultMessage: "Nos ampliamos al sector Marina",
      },
    }).timelineTitle8,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary8: {
        id: "SpradlingTimeline.timelineSummary8",
        description: "Timeline 8 in 'We make history' section",
        defaultMessage:
          "Spradling International, adquiere la división marina de la empresa norteamericana G&T, y así nace Spradling International Marine (SIM).",
      },
    }).timelineSummary8,
  },
  {
    year: 2009,
    title: defineMessages({
      timelineTitle9: {
        id: "SpradlingTimeline.timelineTitle9",
        description: "Timeline 9 in 'We make history' section",
        defaultMessage: "Continúa nuestro crecimiento",
      },
    }).timelineTitle9,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary9: {
        id: "SpradlingTimeline.timelineSummary9",
        description: "Timeline 9 in 'We make history' section",
        defaultMessage: "Nace Spradmex, en la Ciudad de México.",
      },
    }).timelineSummary9,
  },
  {
    year: 2010,
    title: defineMessages({
      timeline2010_1: {
        id: "SpradlingTimeline.timeline2010_1",
        defaultMessage: "ISO 14001 in Costa Rica",
      },
    }).timeline2010_1,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2010_1: {
        id: "SpradlingTimeline.timelineSummary2010_1",
        defaultMessage:
          "Recibimos la certificación ISO 14001 para nuestra planta de Costa Rica, avalando nuestro sistema de gestión ambiental.",
      },
    }).timelineSummary2010_1,
  },
  {
    year: 2014,
    title: defineMessages({
      timelineTitle10: {
        id: "SpradlingTimeline.timelineTitle10",
        description: "Timeline 10 in 'We make history' section",
        defaultMessage: "Spradling Group",
      },
    }).timelineTitle10,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary10: {
        id: "SpradlingTimeline.timelineSummary10",
        description: "Timeline 10 in 'We make history' section",
        defaultMessage: "Se consolida oficialmente Spradling Group.",
      },
    }).timelineSummary10,
  },
  {
    year: 2014,
    title: defineMessages({
      timeline2014_2: {
        id: "SpradlingTimeline.timeline2014_2",
        defaultMessage: "Carbono Neutro en Costa Rica",
      },
    }).timeline2014_2,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2014_2: {
        id: "SpradlingTimeline.timelineSummary2014_2",
        defaultMessage:
          "Logramos esta certificación por la gestión responsable y efectiva de emisiones netas de carbono en nuestra planta de Costa Rica.",
      },
    }).timelineSummary2014_2,
  },
  {
    year: 2016,
    title: defineMessages({
      timelineTitle11: {
        id: "SpradlingTimeline.timelineTitle11",
        description: "Timeline 11 in 'We make history' section",
        defaultMessage: "Cada vez más grandes",
      },
    }).timelineTitle11,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary11: {
        id: "SpradlingTimeline.timelineSummary11",
        description: "Timeline 11 in 'We make history' section",
        defaultMessage:
          "Ampliamos la planta en Costa Rica incrementando nuestra capacidad de producción.",
      },
    }).timelineSummary11,
  },
  {
    year: 2018,
    title: defineMessages({
      timeline2018_1: {
        id: "SpradlingTimeline.timeline2018_1",
        defaultMessage: "ISO 50001",
      },
    }).timeline2018_1,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2018_1: {
        id: "SpradlingTimeline.timelineSummary2018_1",
        defaultMessage:
          "Recibimos la certificación ISO 50001 para nuestra planta de Costa Rica, avalando nuestro sistema de gestión de energía empresarial.",
      },
    }).timelineSummary2018_1,
  },
  {
    year: 2019,
    title: defineMessages({
      timelineTitle12: {
        id: "SpradlingTimeline.timelineTitle12",
        description: "Timeline 12 in 'We make history' section",
        defaultMessage: "Cumplimos 60 años",
      },
    }).timelineTitle12,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary12: {
        id: "SpradlingTimeline.timelineSummary12",
        description: "Timeline 12 in 'We make history' section",
        defaultMessage:
          "Nos consolidamos en el mercado, demostrando que somos expertos en lo que hacemos, nuestros 60 años lo confirman.",
      },
    }).timelineSummary12,
  },
  {
    year: 2019,
    title: defineMessages({
      timelineTitle2019_2: {
        id: "SpradlingTimeline.timelineTitle2019_2",
        description: "Timeline 12 in 'We make history' section",
        defaultMessage: "Cumplimos 60 años",
      },
    }).timelineTitle2019_2,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2019_2: {
        id: "SpradlingTimeline.timelineSummary2019_2",
        description: "Timeline 2019_2 in 'We make history' section",
        defaultMessage:
          "Nos consolidamos en el mercado, demostrando que somos expertos en lo que hacemos, nuestros 60 años lo confirman.",
      },
    }).timelineSummary2019_2,
  },
  {
    year: 2020,
    title: defineMessages({
      timelineTitle2020_1: {
        id: "SpradlingTimeline.timelineTitle2020_1",
        description:
          "Título del timeline para el 2020 - Innovación que trasciende",
        defaultMessage: "Innovación que trasciende",
      },
    }).timelineTitle2020_1,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2020_1: {
        id: "SpradlingTimeline.timelineSummary2020_1",
        description:
          "Resumen del timeline para el 2020 - Innovación que trasciende",
        defaultMessage:
          "Desarrollamos nuevas telas recubiertas 100% impermeables con antifluido permanente, que respondieron a la necesidad del sector salud para la pandemia del Covid-19.",
      },
    }).timelineSummary2020_1,
  },
  {
    year: 2020,
    title: defineMessages({
      timelineTitle2020_2: {
        id: "SpradlingTimeline.timelineTitle2020_2",
        description: "Título del timeline para el 2020 - ISO 14001 en Colombia",
        defaultMessage: "ISO 14001 en Colombia",
      },
    }).timelineTitle2020_2,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2020_2: {
        id: "SpradlingTimeline.timelineSummary2020_2",
        description:
          "Resumen del timeline para el 2020 - ISO 14001 en Colombia",
        defaultMessage:
          "Recibimos la certificación ISO 14001 para nuestra planta de Colombia, avalando nuestro sistema de gestión ambiental.",
      },
    }).timelineSummary2020_2,
  },
  {
    year: 2021,
    title: defineMessages({
      timelineTitle2021_1: {
        id: "SpradlingTimeline.timelineTitle2021_1",
        description: "Título del timeline para el 2021 - Spradling® Resources",
        defaultMessage: "Spradling® Resources",
      },
    }).timelineTitle2021_1,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2021_1: {
        id: "SpradlingTimeline.timelineSummary2021_1",
        description: "Resumen del timeline para el 2021 - Spradling® Resources",
        defaultMessage:
          "Spradling® International Inc. adquiere los activos de CMI Enterprises en Estados Unidos, y así nace Spradling® Resources.",
      },
    }).timelineSummary2021_1,
  },
  {
    year: 2022,
    title: defineMessages({
      timelineTitle2022_1: {
        id: "SpradlingTimeline.timelineTitle2022_1",
        description:
          "Título del timeline para el 2022 - Nuestro desarrollo continúa",
        defaultMessage: "Nuestro desarrollo continúa",
      },
    }).timelineTitle2022_1,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2022_1: {
        id: "SpradlingTimeline.timelineSummary2022_1",
        description:
          "Resumen del timeline para el 2022 - Nuestro desarrollo continúa",
        defaultMessage:
          "Realizamos una inversión de $30 millones de dólares para la tecnificación y diversificación de los procesos productivos en la planta de Costa Rica. Con esto, se adquirió una nueva máquina de calandrado, posicionándonos como un referente de manufactura avanzada y exitosa.",
      },
    }).timelineSummary2022_1,
  },
  {
    year: 2022,
    title: defineMessages({
      timelineTitle2022_2: {
        id: "SpradlingTimeline.timelineTitle2022_2",
        description:
          "Título del timeline para el 2022 - Respaldo en sostenibilidad",
        defaultMessage: "Respaldo en sostenibilidad",
      },
    }).timelineTitle2022_2,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2022_2: {
        id: "SpradlingTimeline.timelineSummary2022_2",
        description:
          "Resumen del timeline para el 2022 - Respaldo en sostenibilidad",
        defaultMessage:
          "Recibimos la certificación ISO 45001 en nuestras dos plantas productivas de Colombia y Costa Rica, por la gestión de seguridad y salud en el trabajo. Además, recibimos las certificaciones Indoor Air Quality y Greenguard Gold por nuestros productos con bajos compuestos orgánicos volátiles (VOC).",
      },
    }).timelineSummary2022_2,
  },
  {
    year: 2023,
    title: defineMessages({
      timelineTitle2023_1: {
        id: "SpradlingTimeline.timelineTitle2023_1",
        description:
          "Título del timeline para el 2023 - Nuevas iniciativas sostenibles",
        defaultMessage: "Nuevas iniciativas sostenibles",
      },
    }).timelineTitle2023_1,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2023_1: {
        id: "SpradlingTimeline.timelineSummary2023_1",
        description:
          "Resumen del timeline para el 2023 - Nuevas iniciativas sostenibles",
        defaultMessage:
          "Lanzamos una nueva línea de productos sostenibles, utilizando materiales reciclados y técnicas de producción de bajo impacto ambiental.",
      },
    }).timelineSummary2023_1,
  },
  {
    year: 2023,
    title: defineMessages({
      timelineTitle2023_2: {
        id: "SpradlingTimeline.timelineTitle2023_2",
        description:
          "Título del timeline para el 2023 - Ampliación de planta en Colombia",
        defaultMessage: "Ampliación de planta en Colombia",
      },
    }).timelineTitle2023_2,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2023_2: {
        id: "SpradlingTimeline.timelineSummary2023_2",
        description:
          "Resumen del timeline para el 2023 - Ampliación de planta en Colombia",
        defaultMessage:
          "Ampliamos nuestra planta en Colombia, agregando nuevas líneas de producción para satisfacer la creciente demanda de nuestros productos.",
      },
    }).timelineSummary2023_2,
  },
  {
    year: 2024,
    title: defineMessages({
      timelineTitle2024_1: {
        id: "SpradlingTimeline.timelineTitle2024_1",
        description:
          "Título del timeline para el 2024 - Innovación en tecnología",
        defaultMessage: "Innovación en tecnología",
      },
    }).timelineTitle2024_1,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2024_1: {
        id: "SpradlingTimeline.timelineSummary2024_1",
        description:
          "Resumen del timeline para el 2024 - Innovación en tecnología",
        defaultMessage:
          "Introducimos una nueva tecnología de revestimiento inteligente para textiles, mejorando la durabilidad y el rendimiento en aplicaciones industriales.",
      },
    }).timelineSummary2024_1,
  },
  {
    year: 2024,
    title: defineMessages({
      timelineTitle2024_2: {
        id: "SpradlingTimeline.timelineTitle2024_2",
        description: "Título del timeline para el 2024 - Expansión global",
        defaultMessage: "Expansión global",
      },
    }).timelineTitle2024_2,
    summaryContainsHtml: false,
    summary: defineMessages({
      timelineSummary2024_2: {
        id: "SpradlingTimeline.timelineSummary2024_2",
        description: "Resumen del timeline para el 2024 - Expansión global",
        defaultMessage:
          "Abrimos nuevas oficinas y centros de distribución en Asia y Europa, fortaleciendo nuestra presencia internacional.",
      },
    }).timelineSummary2024_2,
  },
];
